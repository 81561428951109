export default [
  {
    header: 'Development',
    action: 'read',
    resource: ['V1', 'Backdoor', 'Calendar'],
  },
  {
    title: 'Calendar',
    route: 'calendar',
    icon: 'CalendarIcon',
    action: 'read',
    resource: 'Calendar',
  },
  {
    title: 'V1Import',
    route: 'v1-import',
    icon: 'LinkIcon',
    action: 'read',
    resource: 'V1',
  },
  {
    title: 'Backdoor',
    route: 'backdoor',
    icon: 'KeyIcon',
    action: 'read',
    resource: 'Backdoor',
  },
]
