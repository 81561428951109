export default [
  {
    header: 'Financial',
    action: 'read',
    resource: ['Settlement.Manual', 'Settlement.Report', 'Settlement.Invoice', 'Settlement.Payout', 'Payments.List', 'Payments.Download', 'BankBills.Download', 'BankBills.Query'],
  },
  {
    title: 'Settlement',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: 'Manual',
        route: 'settlement-manual',
        action: 'read',
        resource: 'Settlement.Manual',
      },
      {
        title: 'Report',
        route: 'settlement-report',
        action: 'read',
        resource: 'Settlement.Report',
      },
      {
        title: 'Invoice',
        route: 'settlement-invoice',
        action: 'read',
        resource: 'Settlement.Invoice',
      },
      {
        title: 'Payout',
        route: 'settlement-payout',
        action: 'read',
        resource: 'Settlement.Payout',
      },
      {
        title: 'PayoutDownload',
        route: 'settlement-payout-download',
        action: 'read',
        resource: 'Settlement.Payout',
      },
    ],
  },
  {
    title: 'Payments',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'List',
        route: 'payments-list',
        action: 'read',
        resource: 'Payments.List',
      },
      {
        title: 'Download',
        route: 'payments-download',
        action: 'read',
        resource: 'Payments.Download',
      },
    ],
  },
  {
    title: 'BankBills',
    icon: 'FileIcon',
    children: [
      {
        title: 'Download',
        route: 'bank-bills-download',
        action: 'read',
        resource: 'BankBills.Download',
      },
      {
        title: 'Upload',
        route: 'bank-bills-upload',
        action: 'read',
        resource: 'BankBills.Download',
      },
      {
        title: 'Query',
        route: 'bank-bills-query',
        action: 'read',
        resource: 'BankBills.Query',
      },
    ],
  },
]
