export default [
  {
    header: 'Admin',
    action: 'read',
    resource: ['Users'],
  },
  {
    title: 'Users',
    route: 'user-list',
    icon: 'UsersIcon',
    action: 'read',
    resource: 'Users',
  },
]
