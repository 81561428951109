export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
    action: 'read',
    resource: 'Dashboard',
  },
  {
    title: 'Analytics',
    icon: 'ActivityIcon',
    children: [
      {
        title: 'Method',
        route: 'analytics-method',
        action: 'read',
        resource: 'Analytics.Method',
      },
      {
        title: 'Channel',
        route: 'analytics-channel',
        action: 'read',
        resource: 'Analytics.Channel',
      },
      {
        title: 'Payments',
        route: 'analytics-payment',
        action: 'read',
        resource: 'Analytics.Payment',
      },
    ],
  },
]
