export default [
  {
    header: 'Reseller',
    action: 'read',
    resource: ['Reseller'],
  },
  {
    title: 'ResellerUsers',
    route: 'reseller-users',
    icon: 'ShoppingBagIcon',
    action: 'read',
    resource: 'Reseller',
  },
  // {
  //   title: 'ResellerManagement',
  //   route: 'reseller-management',
  //   icon: 'AwardIcon',
  //   action: 'read',
  //   resource: 'Reseller.Management',
  // },
  {
    title: 'ResellerKyc',
    route: 'kyc-management',
    icon: 'TargetIcon',
    action: 'read',
    resource: 'Reseller.KYC',
  },
]
