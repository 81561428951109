export default [
  {
    header: 'AntiFraud',
    action: 'read',
    resource: ['Anonymous'],
  },
  {
    title: 'Anonymous',
    route: 'anonymous',
    icon: 'CompassIcon',
    action: 'read',
    resource: 'Anonymous',
  },
  // {
  //   title: 'RuleManagement',
  //   route: 'af-rule-management',
  //   icon: 'SlashIcon',
  //   action: 'read',
  //   resource: 'AF.RuleManagement',
  // },
  // {
  //   title: 'TongDun',
  //   icon: 'ShieldIcon',
  //   children: [
  //     {
  //       title: 'Policy',
  //       route: 'td-policy',
  //       action: 'read',
  //       resource: 'AF.TongDun.Policy',
  //     },
  //     {
  //       title: 'Invoke',
  //       route: 'td-invoke',
  //       action: 'read',
  //       resource: 'AF.TongDun.Invoke',
  //     },
  //     {
  //       title: 'RiskTrend',
  //       route: 'td-risk-trend',
  //       action: 'read',
  //       resource: 'AF.TongDun.RiskTrend',
  //     },
  //   ],
  // },
]
