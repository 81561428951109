export default [
  {
    header: 'AntiMoneyLaundry',
    action: 'read',
    resource: ['AML.ID.List', 'AML.ID.BlackList', 'AML.Rules.List', 'AML.Rules.Group', 'Sanctions'],
  },
  {
    title: 'IdManagement',
    icon: 'UserCheckIcon',
    children: [
      {
        title: 'List',
        route: 'aml-id-list',
        action: 'read',
        resource: 'AML.ID.List',
      },
      {
        title: 'Blacklist',
        route: 'aml-id-blacklist',
        action: 'read',
        resource: 'AML.ID.BlackList',
      },
    ],
  },
  // {
  //   title: 'Review',
  //   route: 'aml-transactions-review',
  //   icon: 'CrosshairIcon',
  //   children: [
  //     {
  //       title: 'List',
  //       route: 'aml-transactions-review',
  //       action: 'read',
  //       resource: 'AML.Review.List',
  //     },
  //     {
  //       title: 'Download',
  //       route: 'review-transactions-download-log',
  //       action: 'read',
  //       resource: 'AML.Review.Download',
  //     },
  //   ],
  // },
  {
    title: 'AmlRuleManagement',
    icon: 'NavigationIcon',
    children: [
      {
        title: 'List',
        route: 'aml-rules',
        action: 'read',
        resource: 'AML.Rules.List',
      },
      {
        title: 'Group',
        route: 'aml-rule-group',
        action: 'read',
        resource: 'AML.Rules.Group',
      },
    ],
  },
  {
    title: 'Sanctions',
    route: 'sanctions',
    icon: 'ShieldOffIcon',
    action: 'read',
    resource: 'Sanctions',
  },
  {
    title: 'Training',
    route: 'training',
    icon: 'FramerIcon',
    action: 'read',
    resource: 'Training',
  },
]
