export default [
  {
    header: 'Business',
    action: 'read',
    resource: ['Transaction.List', 'Transaction.Download', 'Transaction.Refund', 'Waybill.List', 'Waybill.Upload', 'Waybill.Download', 'Recurring.List'],
  },
  {
    title: 'Transactions',
    icon: 'TrelloIcon',
    children: [
      {
        title: 'List',
        route: 'transactions-list',
        action: 'read',
        resource: 'Transaction.List',
      },
      {
        title: 'Refund',
        route: 'transactions-refund',
        action: 'read',
        resource: 'Transaction.Refund',
      },
      {
        title: 'Download',
        route: 'transactions-download-log',
        action: 'read',
        resource: 'Transaction.Download',
      },
    ],
  },
  {
    title: 'Waybill',
    icon: 'TruckIcon',
    children: [
      {
        title: 'List',
        route: 'waybill-list',
        action: 'read',
        resource: 'Waybill.List',
      },
      {
        title: 'Upload',
        route: 'waybill-upload',
        action: 'read',
        resource: 'Waybill.Upload',
      },
      {
        title: 'Download',
        route: 'waybill-download',
        action: 'read',
        resource: 'Waybill.Download',
      },
    ],
  },
  {
    title: 'Recurring',
    icon: 'ClockIcon',
    children: [
      {
        title: 'List',
        route: 'recurring-list',
        action: 'read',
        resource: 'Recurring.List',
      },
    ],
  },
]
