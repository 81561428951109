export default [
  {
    header: 'Commercial',
    action: 'read',
    resource: ['Merchants.List', 'Merchants.AppList', 'Merchants.Kyc', 'Channels.List', 'Channels.Management', 'Channels.Group', 'Channels.Swither', 'Promotions', 'Registration', 'VirtualAccount.List'],
  },
  {
    title: 'Merchants',
    icon: 'GridIcon',
    children: [
      {
        title: 'List',
        route: 'merchant-list',
        action: 'read',
        resource: 'Merchants.List',
      },
      {
        title: 'APP',
        route: 'app-list',
        // icon: 'SmartphoneIcon',
        action: 'read',
        resource: 'Merchants.AppList',
      },
      {
        title: 'KYC',
        route: 'kyc-list',
        action: 'read',
        resource: 'Merchants.Kyc',
      },
      {
        title: 'Download',
        route: 'app-download-list',
        action: 'read',
        resource: 'Merchants.AppDownload',
      },
    ],
  },
  {
    title: 'Channels',
    icon: 'LayersIcon',
    children: [
      {
        title: 'List',
        route: 'channel-list',
        action: 'read',
        resource: 'Channels.List',
      },
      {
        title: 'Group',
        route: 'channel-group',
        action: 'read',
        resource: 'Channels.Group',
      },
      {
        title: 'Management',
        route: 'channel-management',
        action: 'read',
        resource: 'Channels.Management',
      },
      {
        title: 'Switcher',
        route: 'switching-channels',
        action: 'read',
        resource: 'Channels.Swither',
      },
    ],
  },
  {
    title: 'VirtualAccount',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'List',
        route: 'virtual-account-list',
        action: 'read',
        resource: 'VirtualAccount.List',
      },
      {
        title: 'Transactions',
        route: 'virtual-account-transactions',
        action: 'read',
        resource: 'VirtualAccount.List',
      },
      {
        title: 'Download',
        route: 'virtual-account-transactions-download-list',
        action: 'read',
        resource: 'VirtualAccount.Download',
      },
    ],
  },
  {
    title: 'Promotions',
    route: 'promotions',
    icon: 'GiftIcon',
    action: 'read',
    resource: 'Promotions',
  },
  {
    title: 'Registration',
    route: 'registration',
    icon: 'Edit2Icon',
    action: 'read',
    resource: 'Registration',
  },
]
